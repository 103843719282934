import React, { useEffect, useState } from 'react';
import { SC } from './styles/homepageIntro.styled';
import { useRouting } from '../../../routing/utils/useRouting';
import RouteLink from '../../app/components/RouteLink';
import { useConfiguration } from '../../../configuration/useConfiguration';
import { selectMainProjectList } from '../reducer/selectors';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../common/hooks/useWindowDimensions';
import { selectPageDetail } from '../../page/reducer/selectors';
import { getValueFromList } from '../../page/helper';
import { PageBlockContentType } from '../../app/models/enums';

interface ComponentPrors {}

const IntroWrapper: React.FC<ComponentPrors> = ({}) => {
  const data = useSelector(selectPageDetail);
  const { isMobile } = useWindowDimensions();

  return (
    <SC.Intro>
      {data?.blocks.map((b: any) => {
        if (b.type == 'MAIN_PROMO' && !isMobile) {
          return (
            <div className={'preview'}>
              <div className={'container'}>
                <div className={'main-promo'}>
                  <div className={'side content'}>
                    <div className="title">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getValueFromList(b.data, PageBlockContentType.MAIN_PROMO_BLOCK_LEFT_SIDE) as string,
                        }}
                      />
                    </div>
                    <div className="description">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getValueFromList(b.data, PageBlockContentType.MAIN_PROMO_BLOCK_RIGHT_SIDE) as string,
                        }}
                      />
                    </div>
                    <div className={'action'}>
                      <a href={'#contact'}>Chci nezávaznou konzultaci </a>
                      <a href={'#services'} className={'second'}>
                        Naše služby
                      </a>
                    </div>
                  </div>
                  <div className={'side image'}>
                    <div className={`logo-link`}>
                      <div className={'circle'}></div>
                      <div className={'circle-reverse'}></div>
                    </div>
                  </div>
                </div>
                <div className={'slogan'}>
                  piece <br /> of cake
                </div>
              </div>
            </div>
          );
        }
        return <></>;
      })}
    </SC.Intro>
  );
};

export default IntroWrapper;
