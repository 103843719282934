import React from 'react';
import { NextComponentProps, NextPageComponent } from '../types';
import Layouts from '../modules/ui/components/layout/type';
import IntroWrapper from '../modules/homepage/components/homepageIntro';
import { SC } from '../modules/homepage/components/styles/homepage.styled';
import { SeoResourceType } from '../modules/app/models/enums';
import { getMainProjectList, getProjectList } from '../modules/homepage/action';
import BlocksComponent from '../modules/common/components/page/blocksComponent';
import { useSelector } from 'react-redux';
import { selectPageDetail } from '../modules/page/reducer/selectors';
import Head from 'next/head';
import ContactForm from '../modules/common/components/contactForm';

const HomePage: NextPageComponent<NextComponentProps> = () => {
  const data = useSelector(selectPageDetail);

  return (
    <SC.Homepage>
      {data?.url && (
        <Head>
          <link rel="canonical" href={data.url} />
        </Head>
      )}
      <IntroWrapper key={'intro-wrapper'} />

      <div className={'container homepage-container'}>
        <BlocksComponent data={data} />
      </div>
      <ContactForm />
    </SC.Homepage>
  );
};

HomePage.settings = {
  pageType: 'homepage',
  layout: Layouts.Default,
  seoResourceType: SeoResourceType.PAGE,
  layoutProps: { isIndex: true },
};

HomePage.getInitialProps = async (ctx) => {
  const {
    query,
    store: { dispatch },
  } = ctx;

  await dispatch(getProjectList({}, '0', '3', null));

  return {};
};

export default HomePage;
