import { useEffect, useState } from 'react';

function getWindowDimensions() {
  if (typeof window == 'undefined') {
    return { width: 1024, height: 1024 };
  }

  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const isMobile = windowDimensions.width <= 725;
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { windowDimensions, isMobile };
}
