import styled, { css } from 'styled-components';
import { sizes } from '../../../../styles/mediaQueries';

export const SC = {
  Intro: styled.div(
    ({ theme }) => css`
      @media (max-width: ${sizes.mDevice}) {
        display: none;
      }

      .preview {
        position: relative;
        text-align: center;
        width: 100%;
        height: 83vh;
        //top: -5rem;
        background: #f7f6f0;
        padding-bottom: 10rem;

        @media (max-width: ${sizes.mDevice}) {
          padding-top: 10vh;
          background: white;
          height: fit-content;
        }

        .button {
          transition: background-color 0.1s ease-in-out;
          border: 1px solid black;
          background: none;
          border-radius: 4px;
          padding: 1.5rem 3rem;
          font: normal 950 15px 'Area Normal Black';
          width: fit-content;
          cursor: pointer;
          user-select: none;
          height: 50px;

          @media (max-width: ${sizes.mDevice}) {
            display: none;
          }

          &.hidden {
            opacity: 0;
            transition: opacity 0.2s ease-in;
          }

          :hover {
            background-color: #f6e600;
            border-color: transparent;
          }
        }

        .company {
          font-family: 'Area Extended Extrablack', serif;
          font-weight: 500;
          font-size: 25vw;
          color: #f0eedf;
          z-index: 10;
          position: absolute;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: 80%;

          @media (max-width: ${sizes.xsDevice}) {
            height: initial;
          }

          &__text {
            width: fit-content;

            @media (max-width: ${sizes.xsDevice}) {
              font-size: 30.5vw;
            }
          }

          //margin-top: 5vw;
          user-select: none;
        }

        &-description {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-left: auto;
          margin-right: 10vw;
          margin-bottom: 10vw;
          width: 30rem;
          text-align: start;
          z-index: 10;

          @media (max-width: ${sizes.xsDevice}) {
            padding: 0 2rem;
            margin: 0;
            width: 100%;
            margin-bottom: 10vh;
          }

          .title {
            padding-bottom: 1rem;
            color: #000;
            opacity: 0.5;
          }

          h2 {
            font: normal normal 950 22px/26px 'Area Normal Black', serif;
            letter-spacing: 0;
            color: #000;
          }

          p {
            font: normal normal medium 16px/20px 'Area Normal Medium';
            letter-spacing: 0;
            color: #000;
            text-align: justify;
            padding: 1.5rem 0;
            @media (max-width: ${sizes.xsDevice}) {
              padding-bottom: 2.5rem;
            }
          }
        }

        .slogan {
          position: absolute;
          bottom: 20vh;
          font: normal normal 950 30px/26px 'Area Normal Black', serif;
          letter-spacing: 0;
          color: #000;
          right: 10vw;
          text-align: start;
          @media (max-width: ${sizes.mDevice}) {
            display: none;
          }
        }
      }

      .main-promo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: left;
        padding-top: 8vh;

        .side {
          &.content {
            .title {
              padding-bottom: 40px;

              h2 {
                text-align: left;
                font: normal normal 950 35px/48px 'Area Extended Extrablack';
                letter-spacing: 0;
                color: #000000;
                opacity: 1;
                margin: 0;
                @media (max-width: ${sizes.mDevice}) {
                  font: normal normal 950 20px/40px 'Area Extended Extrablack';
                }
              }

              .btn-arrow {
                margin-top: 4rem;
              }
            }

            .description {
              p {
                font: normal normal 950 18px/30px 'Area Normal Medium';

                margin-bottom: 2rem;
                @media (max-width: ${sizes.mDevice}) {
                  font: normal normal 950 25px/40px 'Area Extended Extrablack';
                }
              }

              a {
                color: rgb(0, 0, 0);
                cursor: pointer;
                position: relative;
                font-family: 'Area Normal Extrablack', serif;
              }
            }

            .action {
              padding-top: 5rem;
              display: flex;
              gap: 2rem;

              @media (max-width: ${sizes.xsDevice}) {
                display: flex;
                flex-direction: column;
              }

              .second {
                border: 1px solid black;
                background-color: transparent;

                :hover {
                  background-color: #f6e600;
                  border-color: #f6e600;
                }
              }

              a {
                transition: background-color 0.1s ease-in-out;
                border: 1px solid #f6e600;
                border-radius: 4px;
                padding: 1.5rem 3rem;
                font: normal 950 15px 'Area Normal Black';
                width: fit-content;
                cursor: pointer;
                user-select: none;
                background-color: #f6e600;

                :hover {
                  background-color: #e8d900;
                  border-color: #e8d900;
                }
              }
            }
          }

          &.image {
            position: relative;
            overflow: hidden;

            .logo-link {
              display: block;
              position: absolute;
              width: 400px;
              height: 400px;
              transform: rotate(150deg);
              right: 5rem;

              top: 4rem;
              @media (max-width: ${sizes.xsDevice}) {
                padding-top: 1.4rem;
                z-index: 999;
              }

              .logo {
                font-family: 'Area Extended Extrablack', serif;
                font-weight: 500;
                font-size: 48px;
                @media (max-width: ${sizes.xsDevice}) {
                  font-size: 25px;
                }
              }

              .circle-reverse {
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                margin-left: 0;
                margin-right: 0;
                z-index: 9;

                @media (max-width: ${sizes.xsDevice}) {
                  padding-top: 1.45rem;
                  z-index: 999;
                }

                &:after {
                  content: '';
                  position: absolute;
                  border: 200px solid transparent;
                  border-bottom-color: #f7f6f0;
                  border-left-color: black;
                  transform: rotate(25deg);
                  border-radius: 50%;
                  transition: transform 0.3s ease-in;
                }
              }

              .circle {
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                margin-left: 0;
                margin-right: 0;
                z-index: 10;

                &:before {
                  content: '';
                  position: absolute;
                  width: 400px;
                  height: 200px;
                  background: #f8e800;
                  border-radius: 197px 197px 0 0;
                }

                &:after {
                  content: '';
                  position: absolute;
                  border: 200px solid transparent;
                  border-bottom-color: #f8e800;
                  border-left-color: #f8e800;
                  transform: rotate(-90deg);
                  border-radius: 50%;
                  transition: transform 0.3s ease-in;

                }
              }
            }
          }

          //.circle {
          //  display: block;
          //  position: absolute;
          //  -webkit-animation-play-state: paused;
          //  -moz-animation-play-state: paused;
          //  -o-animation-play-state: paused;
          //  animation-play-state: paused;
          //  left: 0;
          //  right: 0;
          //  margin-right: auto;
          //  margin-left: auto;
          //  width: 50rem;
          //  height: 50rem;
          //  padding-left: 15rem;
          //
          //  &:before {
          //    content: '';
          //    position: absolute;
          //    width: 50rem;
          //    height: 50rem;
          //    background: #f8e800;
          //    border-radius: 100%;
          //    top: 50%;
          //    -ms-transform: translateY(-50%);
          //    transform: translateY(-50%);
          //  }
          //
          //  //&:after {
          //  //  content: '';
          //  //  position: absolute;
          //  //  border: 100px solid transparent;
          //  //  border-bottom-color: #f8e800;
          //  //  border-left-color: #f8e800;
          //  //  transform: rotate(135deg);
          //  //  border-radius: 100%;
          //  //  transition: transform 0.3s ease-in;
          //  //}
          //}
          //
          //.triangle {
          //  overflow: hidden;
          //
          //  &:before {
          //    content: '';
          //    position: absolute;
          //    width: 50rem;
          //    height: 50rem;
          //    background: #000;
          //    border-radius: 100%;
          //    top: 50%;
          //    right: 50%;
          //  }
          //}

          //&:hover .circle::after {
          //  content: '';
          //  position: absolute;
          //  border-bottom-color: #f8e800;
          //  border-left-color: #f8e800;
          //  transform: rotate(-45deg);
          //}
        }
      }
    }
    `
  ),
};
