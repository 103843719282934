import styled, {css} from 'styled-components';
import {sizes} from '../../../../styles/mediaQueries';

export const SC = {
  Homepage: styled.div(
    ({ theme }) => css`
      .homepage-container {
        padding-top: 120px;
        @media (max-width: ${sizes.mDevice}) {
          padding-top: 0;
        }
      }
    `
  ),
};
