import {ActionFunction} from '../../../types';
import {Action} from 'redux';
import createReduxAction from '../../common/actionCreator';
import {ProjectFinderPayload} from '../../project/models';

export function getMainProjectList(
  data: ProjectFinderPayload,
  page?: string | null,
  size?: string | null,
  sort?: string | null
): ActionFunction<Action> {
  return createReduxAction({
    type: 'GET_MAIN_PROJECT_LIST_ACTION',
    promise: ({ api }) =>
      api.post('FILTER_PROJECT', {
        urlParams: { page, size, sort },
        data,
      }),
  });
}

export function getProjectList(
  data: ProjectFinderPayload,
  page?: string | null,
  size?: string | null,
  sort?: string | null
): ActionFunction<Action> {
  return createReduxAction({
    type: 'GET_HOMEPAGE_PROJECT_LIST_ACTION',
    promise: ({ api }) =>
      api.post('FILTER_PROJECT', {
        urlParams: { page, size, sort },
        data,
      }),
  });
}
